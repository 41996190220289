import * as React from "react"
/* import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image" */

import Layout from "../components/layout"
import Seo from "../components/seo"
import reelVideo from "../video/reel.mp4"
import AppStore from "./../svg/appstore.svg"
import GooglePlay from "./../svg/google-play.svg"

function IndexPage(){
  return(
  <Layout>
    <Seo title="MakaMaka" />
    <section className="hero-text">
      <h1 className="hero-headline">Место где собраны лучшие развлечения</h1>
      <p className="hero-paragraph">
        Мы запустили клуб для поиска единомышленников по интересам и жизненным
        ценностям. Дружба будет строиться во время совместных развлечений,
        развития, изучений новых хобби и любых проектах, которые будем создавать
        друг другу по всей стране.
      </p>
      <div style={{display:"none"}} className="badges">
        <AppStore />
        <GooglePlay />
      </div>
    </section>
    <section className="fullscreen-video">
      <div>
        <video autoPlay muted playsInline loop>
          <source src={reelVideo} type="video/mp4" />
        </video>
      </div>
    </section>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      placeholder={"tracedSVG"}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
  </Layout>
)}

export default IndexPage
